import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SweetalertService {

  swalWithBootstrapButtons: any = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'me-2 btn btn-danger'
    },
    buttonsStyling: false
  })

  constructor(private translate: TranslateService) { }

  confirmActionBtn(msg: any) {
    return this.swalWithBootstrapButtons.fire({
      title: this.translate.instant('data.title_alert'),
      text: msg, //"Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('data.ok_alert'),
      cancelButtonText: this.translate.instant('data.no_alert'),
      reverseButtons: true
    })
  }

  // alertSuccess(text: any) {
  //   return this.swalWithBootstrapButtons.fire({
  //     title: "Succès",
  //     text: text,
  //   })
  // }
  alertSuccess(text: any) {
    return this.swalWithBootstrapButtons.fire({
      title: this.translate.instant('data.success'), // Titre traduit
      text: text, // Contenu du message
      icon: "success", // ✅ Toujours en anglais
      confirmButtonText: this.translate.instant('data.ok_alert') // Traduction du bouton OK
    });
}

  alertConfirmed(msg: any) {
    return this.swalWithBootstrapButtons.fire(
      this.translate.instant('data.delete_alert'),
      msg, // 'Votre fichier a été supprimé.',
      this.translate.instant('data.success')
    )
  }

  alertProblem(msg: any) {
    return this.swalWithBootstrapButtons.fire(
      'Attention!',
      msg,
      'Warning'
    )
  }

  alertCanceled() {
    return this.swalWithBootstrapButtons.fire(
      this.translate.instant('data.cancelled'),
      this.translate.instant('data.message'),
      this.translate.instant('data.error'),
    )
  }
}
