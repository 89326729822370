<div class="app-page-title">
    <div class="page-title-wrapper">
        <div class="page-title-heading">
            <div class="page-title-icon">
                <i class="pe-7s-car icon-gradient bg-mean-fruit">
                </i>
            </div>
            <div>{{'data.dashboard' | translate }}
                <div class="page-title-subheading">{{'data.headerDashboard' | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']" [queryParams]="{ status: ['invalid'] }">
        <div class="card mb-3 widget-content bg-danger">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-more text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">{{'data.invalide' | translate }}</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.invalid?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']" [queryParams]="{ status: ['validated'] }">
        <div class="card mb-3 widget-content bg-warning">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-more text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">{{'data.pending' | translate }}</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.validated?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']" [queryParams]="{ status: ['take_off'] }">
        <div class="card mb-3 widget-content bg-info">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">{{'data.picked_up' | translate }}</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.take_off?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']"
        [queryParams]="{ status: ['transporting_depot'] }">
        <div class="card mb-3 widget-content bg-info">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">{{'data.at_depot' | translate }}</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.transporting_depot?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']" [queryParams]="{ status: ['prepared'] }">
        <div class="card mb-3 widget-content bg-primary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-home text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">{{'data.prepare' | translate }}</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.prepared?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']"
        [queryParams]="{ status: ['transporting_to', 'transporting_between', 'transporting', 'transported', 'arrived'] }">
        <div class="card mb-3 widget-content bg-primary">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-car text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">{{'data.in_progress' | translate }}</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.inProgress?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']"
        [queryParams]="{ status: ['delivered'], paied: false }">
        <div class="card mb-3 widget-content bg-success">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-like2 text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">
                    <div class="widget-heading">{{'data.delivered' | translate }}</div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white">
                        <span>{{dataCmds?.deliveredUserTrack?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6 col-xl-3" [routerLink]="['/status-commandes']"
        [queryParams]="{ status: ['delivered'], paied: true }">
        <div class="card mb-3 widget-content bg-success">
            <div class="widget-content-wrapper text-white">
                <div class="widget-content-left mr-3">
                    <div class="icon-wrapper rounded-circle">
                        <div class="icon-wrapper-bg bg-primary"></div>
                        <i class="pe-7s-like2 text-white"></i>
                    </div>
                </div>
                <div class="widget-content-left">{{'data.delivered_paid' | translate }}
                    <div class="widget-heading"></div>
                </div>
                <div class="widget-content-right">
                    <div class="widget-numbers text-white"><span>{{dataCmds?.delivered?.nbrCmd}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12">

        <div class="main-card mb-3 card">
            <div class="card-header">{{'data.list_current_orders' | translate}}
                <div class="btn-actions-pane-right">
                    <button type="button" id="PopoverCustomT-1"
                        class="btn btn-danger btn-sm">{{cmdList.length}}</button>
                </div>
            </div>
            <div class="table-responsive">
                <div class="budget-price justify-content-center" *ngIf="cmdList?.length === 0">
                    <h2 class="text-center text-danger">{{'data.no_pickup' | translate}}</h2>
                </div>
                <table class="align-middle mb-0 table table-borderless table-hover" *ngIf="cmdList?.length > 0">
                    <thead>
                        <tr>
                            <!-- <th class="text-center">#</th> -->
                            <th>{{'data.provider' | translate}}</th>
                            <th class="text-left">{{'data.reference' | translate }}</th>
                            <th class="text-center">{{'data.creation_date' |translate}}</th>
                            <!-- <th class="text-center">Destination</th> -->
                            <!-- <th class="text-center">{{'data.pending' | translate}}</th>
                            <th class="text-center">{{'data.at_depot' | translate}}</th>
                            <th class="text-center">{{'data.prepare' | translate}}</th>
                            <th class="text-center">{{'data.in_transport' | translate}}</th>
                            <th class="text-center">{{'data.delivered' | translate}}</th>
                            <th class="text-center">{{'data.payment_requested' | translate}}</th>
                            <th class="text-center">Commentaire</th> -->
                            <th class="text-center">{{'data.assigned_to' | translate}}</th>
                            <th class="text-center">{{'data.status' | translate}}</th>
                            <th class="text-center">prévu</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let cmd of cmdList, let index = index" [class]="cmd.planned.colorTime"
                            class="border-tr">
                            <!-- <td class="text-center text-muted">#345</td> -->
                            <td>
                                <div class="widget-content p-0">
                                    <div class="widget-content-wrapper">
                                        <div class="widget-content-left mr-3">
                                            <div class="widget-content-left">
                                                <ngx-avatar class="rounded-circle" name="{{cmd?.element?.client_cmd?.prenom_client}}
                                                                {{cmd?.element?.client_cmd?.nom_client}}">
                                                </ngx-avatar>
                                            </div>
                                        </div>
                                        <div class="widget-content-left flex2">
                                            <div class="widget-heading">
                                                {{cmd?.element?.client_cmd?.providerName}}
                                            </div>
                                            <div class="widget-subheading opacity-7">
                                                {{cmd?.element?.client_cmd?.prenom_client}}
                                                {{cmd?.element?.client_cmd?.nom_client}}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="text" style="width: 250px; word-wrap: break-word; word-break: break-all;"><a
                                    (click)="goToNewTab(cmd.element._id, '/detail-cmd')">{{cmd.element.ref_facture_cmd}}</a>
                            </td>
                            <!-- <td class="text-center">
                                                {{cmd.element.date_creation_cmd | date:'d MMM y'}}<br>
                                                <b>{{cmd.element.date_creation_cmd | date:'HH:mm'}}</b>
                                            </td> -->
                            <!-- <td class="text-center">
                                <strong>{{cmd.element.destination_cmd}}</strong>
                            </td> -->
                            <td class="text-center">
                                <div *ngIf="cmd.element.etat_cmd == 'validated'"
                                    class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{cmd.element.date_creation_cmd | date:'d MMM y'}}<br>
                                <b>{{cmd.element.date_creation_cmd | date:'HH:mm'}}</b>
                            </td>
                            <!-- <td class="text-center">
                                <div *ngIf="cmd.element.etat_cmd == 'transporting_depot'"
                                    class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{cmd.element?.pcollect_transport?.date_accept | date:'d MMM y'}}<br>
                                <b>{{cmd.element?.pcollect_transport?.date_accept | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div *ngIf="cmd.element.etat_cmd == 'prepared'"
                                    class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{cmd.element?.prepared_by?.date_accept | date:'d MMM y'}}<br>
                                <b>{{cmd.element?.prepared_by?.date_accept | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div *ngIf="cmd.element.etat_cmd == 'transporting' || cmd.element.etat_cmd == 'transported' || cmd.element.etat_cmd == 'arrived'"
                                    class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{cmd.element?.transport_topcollect?.date_accept | date:'d MMM y'}}<br>
                                <b>{{cmd.element?.transport_topcollect?.date_accept | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div *ngIf="cmd.element.etat_cmd == 'delivered'"
                                    class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{cmd.element?.date_relivered_cmd | date:'d MMM y'}}<br>
                                <b>{{cmd.element?.date_relivered_cmd | date:'HH:mm'}}</b>
                            </td>
                            <td class="text-center">
                                <div *ngIf="cmd.element.etat_cmd == 'payment_request' || cmd.element.etat_cmd == 'returned'"
                                    class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    <div class="check-badge"><i class="fa fa-check"></i></div>
                                </div><br>
                                {{cmd.element?.date_payment_request | date:'d MMM y'}}<br>
                                <b>{{cmd.element?.date_payment_request | date:'HH:mm'}}</b>
                            </td> -->
                            <!-- <td class="text-center">
                                <span class="badge badge-pill badge-danger"
                                    (click)="listComments(cmd)">{{cmd.element?.comments?.length}}</span>
                            </td> -->
                            <td class="text-center" style="color: red;">
                                <strong>{{cmd.element.transport_affect[cmd.element.transport_affect.length-1]?.transporter_id?.nom_prenom_tracking}}</strong>
                            </td>
                            <td class="text-center">
                                <div class="badge badge-{{statusCmds[cmd.element.etat_cmd].color}}">
                                    {{statusCmds[cmd.element.etat_cmd].label | translate }}</div>
                            </td>
                            <td class="text-center">{{cmd.planned.plannedTime | date:'d MMM'}}<br>
                                <b>{{cmd.planned.plannedTime | date:'HH:mm'}}</b>
                            </td>
                            <!-- <td></td> -->
                            <!-- <td class="text-center">
                                                <button type="button" id="PopoverCustomT-1"
                                                    class="btn btn-primary btn-sm">Details</button>
                                            </td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="d-block text-center card-footer">
                                <button class="mr-2 btn-icon btn-icon-only btn btn-outline-danger"><i
                                        class="pe-7s-trash btn-icon-wrapper"> </i></button>
                                <button class="btn-wide btn btn-success">Save</button>
                            </div> -->
        </div>
    </div>
</div>
<div class="row">
    <div class="col-md-12 col-lg-6">
        <div class="mb-3 card">
            <div class="card-header-tab card-header-tab-animation card-header">
                <div class="card-header-title">
                    <i class="header-icon lnr-apartment icon-gradient bg-love-kiss"> </i>
                    {{'data.delivery_movement' | translate}}
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div class="tab-pane fade show active">
                        <div class="scroll-area-sm">
                            <div class="scrollbar-container">
                                <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                    <li class="list-group-item" *ngFor="let leader of leadersList">
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3">
                                                    <!-- <img width="42" class="rounded-circle"
                                                        src="assets/images/avatars/9.jpg" alt=""> -->
                                                    <img width="40" height="40"
                                                        src="{{'https://assets.marketbey.tn/Elloumi/trans-societies/logo/' + leader?.logo}}"
                                                        alt="" style="border: 1px solid #000;">
                                                    <!-- <ngx-avatar class="rounded-circle"
                                                        name="{{leader.element.nom_prenom_tracking}}">
                                                    </ngx-avatar> -->
                                                </div>
                                                <div class="widget-content-left" style="width: 45%;">
                                                    <div class="widget-heading">
                                                        {{leader?.name}}</div>
                                                    <div class="widget-subheading">
                                                        {{leader?.adress?.label_target}}</div>
                                                </div>
                                                <div class="widget-content-right">
                                                    <div class="badge badge-primary">
                                                        <div class="style-badge">
                                                            {{leader?.transportingCmd}}</div>
                                                        {{'data.in_progress' | translate}}
                                                    </div>
                                                </div>
                                                <div class="widget-content-right">
                                                    <div class="badge badge-success">
                                                        <div class="style-badge">{{leader?.liveredCmd}}
                                                        </div>{{'data.delivered' | translate}}
                                                    </div>
                                                </div>
                                                <div class="widget-content-right">
                                                    <div class="badge badge-danger">
                                                        <div class="style-badge">{{leader?.returningCmd}}
                                                        </div>{{'data.returned' | translate}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 col-lg-6">
        <div class="mb-3 card">
            <div class="card-header-tab card-header-tab-animation card-header">
                <div class="card-header-title">
                    <i class="header-icon lnr-apartment icon-gradient bg-love-kiss"> </i>
                    {{'data.current_status_clients' | translate}}
                </div>
            </div>
            <div class="card-body">
                <div class="tab-content">
                    <div class="tab-pane fade show active">
                        <div class="scroll-area-sm">
                            <div class="scrollbar-container">
                                <ul class="rm-list-borders rm-list-borders-scroll list-group list-group-flush">
                                    <li class="list-group-item" *ngFor="let element of clientsList, let index = index">
                                        <div class="widget-content p-0">
                                            <div class="widget-content-wrapper">
                                                <div class="widget-content-left mr-3">
                                                    <ngx-avatar class="rounded-circle" name="{{element.client.prenom_client}}
                                                                {{element.client.nom_client}}">
                                                    </ngx-avatar>
                                                </div>
                                                <div class="widget-content-left" style="width: 55%;">
                                                    <div class="widget-heading">
                                                        {{element.client.prenom_client}}
                                                        {{element.client.nom_client}}
                                                        ({{element.client.providerName}})</div>
                                                    <div class="widget-subheading">
                                                        {{element.client.contactNumTel}}</div>
                                                </div>
                                                <div class="widget-content-right" *ngIf="element.hasDemande">
                                                    <div class="badge badge-primary">
                                                        <div class="style-badge"><i class="fa fa-check"></i></div>
                                                        Demande
                                                    </div>
                                                </div>
                                                <div class="widget-content-right">
                                                    <div class="badge badge-warning">
                                                        <div class="style-badge">{{element.nbrPickUp}}
                                                        </div>Pick-up
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>