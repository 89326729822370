import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TeamsService } from '../teams-cofat/teams.service';

@Injectable({
  providedIn: 'root'
})
export class CommandesService {

  idUser: string
  idSociety: string;

  constructor(private http: HttpClient,
    private router: Router,
    private userService: TeamsService,) {
    this.idUser = this.userService.getIdUser();
    this.idSociety = this.userService.getIdSociety();
  }

  formatNumber(num: number, precision: number): number {
    return parseFloat(num.toFixed(precision));
  }

  uploadFiles(files: FileList) {
    const formData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('files', file, file.name)
    });

    return new Promise((slv) => {
      this.http.post(environment.apiUploadFiles, formData).subscribe(
        (data) => {
          slv(data)
        },
        (error) => {
          slv(null);
        }
      );

    });
  }

  updateCmd(idOrder, order) {
    return new Promise((slv) => {
      this.http.post(environment.apiUpdateCmd, {
        idOrder,
        order
      }).subscribe(
        (data: any) => {
          if (data) {
            slv(data);
          } else slv(null);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }

  addCmd(order) {
    return new Promise((slv) => {
      this.http.post(environment.apiAddCmd, order).subscribe(
        (data: any) => {
          if (data) {
            slv(data);
          } else slv(null);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }

  calculatePallets(data: any) {
    return new Promise((slv) => {
      this.http.post(environment.apiCalculatePallets, data).subscribe(
        (data: any) => {
          slv(data);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }

  allSocieties() {
    return new Promise((slv) => {
      this.http.post(environment.apiAllSocieties, {
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  deleteCmd(idCmd: any) {
    return new Promise((slv) => {
      this.http
        .post(environment.apiDeleteCmd, {
          idCmd
        })
        .subscribe(
          (data: any) => {
            // console.log("data update status : ");
            // console.log(data);
            if (data) {
              slv(data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }

  confirmPickup(idCmd: any) {
    var user = this.userService.getCurrentUserValue()
    return new Promise((slv) => {
      this.http
        .post(environment.apiConfirmPickup, {
          idCmd: idCmd,
          userName: user?.prenom + ' ' + user?.nom
        })
        .subscribe(
          (data: any) => {
            // console.log("data update status : ");
            // console.log(JSON.stringify(data));
            if (data && data.response) {
              slv(true);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }

  validateCmds(data: any) {
    return new Promise((slv) => {
      this.http.post(environment.apiValidateCmds, data).subscribe(
        (data: any) => {
          // console.log(data);
          if (data) {
            slv(data);
          } else slv(null);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(null);
        }
      );
    });
  }

  sendMail(data: any) {
    return new Promise((slv) => {
      this.http.post(environment.apiSendMail, data).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data) {
            slv(data);
          } else slv(null);
        },
        (error) => {
          // console.log("Cmd error")
          //  console.log(error)
          slv(null);
        }
      );
    });
  }

  getOneCMD(ref) {
    // console.log("ref : " + ref)
    return new Promise((slv) => {
      this.http.post(environment.apiOneCmd + "/" + ref, {}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  getLabeledStatus() {
    // ["pending", "validated", "preparing", "submited", "transported", "arrived", "delivered", "returned", "refused", "canceled"],
    return new Promise((slv) => {
      this.http.post(environment.apiStatus, {}).subscribe(
        (data: any) => {
          // console.log(JSON.stringify(data));
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  // allCmdsUserByStatus(idUser, status) {
  //   let listStatus = []
  //   status.forEach(element => {
  //     listStatus.push({ etat_cmd: element })
  //   });

  //   return new Promise((slv) => {
  //     this.http.post(environment.apiAllCmdsByStatusAndUser, {
  //       transport: idUser,
  //       status: listStatus
  //     }).subscribe(
  //       (data: any) => {
  //         if (data && data.response && data.data) {
  //           slv(data.data);
  //         } else slv(false);
  //       },
  //       (error) => {
  //         // console.log("Cmd error")
  //         // console.log(error)
  //         slv(false);
  //       }
  //     );
  //   });
  // }

  allCmdsByStatus(idClient) {
    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByStatus, {
        client: idClient,
        society: this.idSociety
      }).subscribe(
        (data: any) => {
          // console.log("dataaaa : ")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  // allCmdsByVille(ville) {
  //   return new Promise((slv) => {
  //     this.http.post(environment.apiAllCmdsByVille, {
  //       ville: ville
  //     }).subscribe(
  //       (data: any) => {
  //         // console.log("dataaaa : ")
  //         // console.log(data)
  //         if (data && data.response && data.data) {
  //           slv(data.data);
  //         } else slv(false);
  //       },
  //       (error) => {
  //         // console.log("Cmd error")
  //         // console.log(error)
  //         slv(false);
  //       }
  //     );
  //   });
  // }

  updateCmdStatus(id, status) {
    return new Promise((slv, reject) => {
      this.http
        .post(environment.apiUpdateCmdStatus, {
          cmd: id,
          newstatus: status,
        })
        .subscribe({
          next: (data: any) => {
            console.log("data update status : ");
            console.log(JSON.stringify(data));
            if (data && data.response) {
              slv(true);
            } else slv(false);
          }, error: (err: any) => {
            reject(err.error);
          }
        });
    });
  }

  // paiedCmdByTransporter(idCmd) {
  //   return new Promise((slv) => {
  //     this.http
  //       .post(environment.apiPaiedCmdsByTransporter, {
  //         cmd: idCmd
  //       })
  //       .subscribe(
  //         (data: any) => {
  //           if (data && data.response) {
  //             slv(true);
  //           } else slv(false);
  //         },
  //         (error) => {
  //           slv(false);
  //         }
  //       );
  //   });
  // }

  // validateReturnedCmdsByTransporter(idCmd, returned_by) {
  //   return new Promise((slv) => {
  //     this.http
  //       .post(environment.apiValidateReturnedCmds, {
  //         cmd: idCmd,
  //         returned_by: returned_by
  //       })
  //       .subscribe(
  //         (data: any) => {
  //           if (data && data.response) {
  //             slv(true);
  //           } else slv(false);
  //         },
  //         (error) => {
  //           slv(false);
  //         }
  //       );
  //   });
  // }

  searchCmd(idClient, keyword) {
    return new Promise((slv) => {
      this.http.post(environment.apiSearchCmd, {
        client: idClient,
        keyword: keyword,
        society: this.idSociety
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  allCmdsClientByStatus(page, idClient, status, isPaied, returnedBy) {
    let listStatus = []
    status.forEach(element => {
      listStatus.push({ etat_cmd: element })
    });

    return new Promise((slv) => {
      this.http.post(environment.apiAllCmdsByStatusAndClient, {
        page,
        client: idClient,
        status: listStatus,
        paied: isPaied,
        returnedBy: returnedBy,
        society: this.idSociety
      }).subscribe(
        (data: any) => {
          // console.log("data")
          // console.log(data)
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          // console.log("Cmd error")
          // console.log(error)
          slv(false);
        }
      );
    });
  }

  filterCmds(filter) {
    filter.society = this.idSociety
    return new Promise((slv) => {
      this.http
        .post(environment.apiFilterCmds, filter)
        .subscribe(
          (data: any) => {
            if (data && data.response && data.data) {
              slv(data.data);
            } else slv(false);
          },
          (error) => {
            slv(false);
          }
        );
    });
  }

  // allComments() {
  //   return new Promise((slv) => {
  //     this.http.post(environment.apiAllComments, {
  //     }).subscribe(
  //       (data: any) => {
  //         // console.log(data);
  //         if (data && data.response && data.data) {
  //           slv(data.data);
  //         } else slv(null);
  //       },
  //       (error) => {
  //         // console.log("Cmd error")
  //         // console.log(error)
  //         slv(null);
  //       }
  //     );
  //   });
  // }

  statisticsCmds(client, startDate, endDate, ville) {
    return new Promise((slv) => {
      this.http.post(environment.apiStatsCmds, {
        client,
        startDate,
        endDate,
        ville
      }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  async statisticFluxCmds() {
    return await new Promise((slv) => {
      this.http.post(environment.apiStatisticsFluxCmds, { society: this.idSociety }).subscribe(
        (data: any) => {
          if (data && data.response && data.data) {
            slv(data.data);
          } else slv(false);
        },
        (error) => {
          slv(false);
        }
      );
    });
  }

  goToNewTab(idCmd, route) {
    const queryParams = {
      cmd: idCmd
    };
    const url = this.router.serializeUrl(
      this.router.createUrlTree([route], {
        queryParams: queryParams,
        // relativeTo: this.activeRoute,
        // skipLocationChange: true
      })
    );

    window.open(url, '_blank');
  }

}
